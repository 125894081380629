.formMargin{
  margin-top: 2%;
}

.fileHandleButton{
  margin-left: 1%;
}

.brMargin{
  margin-top: 1%;
  margin-bottom: 1%;
}

.textAlign{
  text-align: justify;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #efefef;
  text-align: center;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100vh;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.rowBody {
  margin-bottom: 1.5%;
}

.tableBordered {
    border: 2px solid #777777;
}

.brMargin {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.pMargin {
  padding-top: 2%;
}

